import { useState, useEffect } from "react";
import { Col, Row, Alert, Container } from "react-bootstrap";
import { ArrowDownCircle } from 'react-bootstrap-icons';
import axios from 'axios';
import Confetti from 'react-dom-confetti';
import checkIcon from '../assets/img/check-circle.svg';


export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40, // Increase this for higher confetti
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const handleResize = () => {
    // Update the button text based on screen size
    setIsMobile(window.innerWidth <= 768 ? true : false)
  };

  useEffect(() => {
    // Set the mobile state based on the viewport width
    setIsMobile(window.innerWidth <= 768 ? true : false);

    // Add an event listener for window resize events
    window.addEventListener('resize', handleResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(email)) {
      setAlert('Please enter a valid email address.');
      setTimeout(() => setAlert(''), 2000); // Clear the alert after 5 seconds
      return;
    }

    // Set a preliminary success message
    setConfetti(true);

    try {
      setSubmitting(true);
      const response = await axios.post(
        'https://script.google.com/macros/s/AKfycbyrAdWAraFiKZS8mPolTP7fDTJ8g3x22Qcz1Cg8Urmi7RxJmVUNmhUbEYsATSSSf8-x0g/exec',
        JSON.stringify({ email }),
        {
          headers: {
            'Content-Type': 'text/plain;charset=utf-8',
          },
          redirect: 'follow',
        }
      );
      setSubmitting(false);
      setSubmitted(true);

      if (response.data.result === 'success') {
        setAlert('Thank you for joining the waitlist! Stay tuned for updates! 🎉');
        setTimeout(() => setConfetti(false), 3000); // Stop the confetti after 3 seconds
      } else {
        throw new Error('API call succeeded but result was not success');
      }
    } catch (error) {
      setSubmitting(false);
      console.error('Error adding email to the waitlist:', error);
      setAlert('There was an error. Please try again.');
      setConfetti(false); // Stop the confetti
    }
  };

  const clearFields = () => {
    setEmail('');
  };
  return (
    <Container className="d-flex justify-content-center">
      <Row className="w-100 justify-content-center">
        <Col lg={8} xs={11} md={11}>
          <div className="newsletter-bx">
            <button className="mb-4">
              Join the Waitlist <ArrowDownCircle size={25} style={{ marginLeft: '5px' }} />
            </button>
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Confetti active={confetti} config={config} />
            </div>
            {!submitted ? (
              <Col lg={12} md={12} sm={12} xs={12} className="ml-auto mb-4">
                <form onSubmit={handleSubmit}>
                  <div className="new-email-bx w-100">
                    <input
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />
                    {alert && <p style={{ color: 'red' }}>{alert}</p>}
                    <button
                      type="submit"
                      disabled={submitting}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {submitting ? 'Submitting...' : isMobile ? <img src={checkIcon} alt="Check Icon" className="check-icon" /> : 'Notify Me'}
                    </button>
                  </div>
                </form>
              </Col>
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  color: '#77d4fc',
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  fontFamily: 'Fasthand',
                  fontSize: '30px'
                }}
              >
                {alert}
              </p>
            )}
            <p className="text-center text-muted mt-4" style={{ textAlign: 'center', color: '#b8b8b8', paddingTop: '10px', paddingBottom: '5px' }}>
              TV Posts Inc. 2024
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
