import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import gif1 from '../assets/img/confused-eleven.gif';
import gif2 from '../assets/img/playing-with-a-teddy-bear-simon-cowell.gif';
import gif3 from '../assets/img/sudden-frown-penelope-featherington.gif';
import gif4 from '../assets/img/homelander-champion.gif';
import gif6 from '../assets/img/breaking-bad-walter-white.gif';
import gif5 from '../assets/img/office-focalette-kelly-kapoor.gif';
import gif7 from '../assets/img/GOT-emilia-clarke-gen.gif';
import gif8 from '../assets/img/fallon-celinemagnien.gif';
import { MailchimpForm } from "./MailchimpForm";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(25 + Math.random() * 25);
  const [index, setIndex] = useState(1);
  const [currentGifs, setCurrentGifs] = useState([]);
  const [showGifs, setShowGifs] = useState(false);
  const [numGifs, setNumGifs] = useState(4); // New state variable for number of GIFs
  const toRotate = ["Shows", "People", "GIFs", "Merch"];
  const gifs = [gif1, gif2, gif3, gif4, gif5, gif6, gif7, gif8]; // Add more GIFs as needed
  const period = 150;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set the number of GIFs based on the viewport width
    setNumGifs(window.innerWidth <= 768 ? 8 : 4); // 768px is a common breakpoint for mobile screens
    // Set the mobile state based on the viewport width
    setIsMobile(window.innerWidth <= 768 ? true : false);

    // Add an event listener for window resize events
    window.addEventListener('resize', handleResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  useEffect(() => {
    // Trigger the GIF animation after 4 seconds
    const timer = setTimeout(() => {
      setShowGifs(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleResize = () => {
    // Update the number of GIFs when the window is resized
    setNumGifs(window.innerWidth <= 768 ? 8 : 4);
    setIsMobile(window.innerWidth <= 768 ? true : false)
  };

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
      setCurrentGifs(gifs); // Show all GIFs when full text is reached
      setShowGifs(true); // Show GIFs
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(375);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
    if (!isDeleting && text === fullText) {
      setTimeout(() => {
        setIsDeleting(true);
      }, 5000); // Add a 1 second delay
    }
  }

  return (
    <section className="banner" id="home">
      <Container fluid style={{ padding: 0, margin: 0 }}>
        <Row className="aligh-items-center">
          <Col xs={12} md={12} className="text-center">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <div className="transparent-div">
                    <h1>
                      {`Your favorite TV `}
                      <span style={{ display: 'inline-block', width: '100px' }}>
                        <span className="txt-rotate" dataPeriod="500" data-rotate='[ "Shows", "People", "GIFs", "Merch" ]'>
                          <span className="wrap">{text}</span>
                        </span>
                      </span>                    </h1>
                    <div className={`background-gifs ${showGifs ? 'animate' : ''}`}>
                      {gifs.slice(0, numGifs).map((gif, index) => ( // Only display the first numGifs GIFs
                        <img key={index} src={gif} alt={`Background GIF ${index}`} className="stay-put" />
                      ))}
                    </div>

                  </div>
                  <p style={{ padding: '40px 20px 0px' }}>The ultimate social network <br />for TV fans, by TV fans.</p>
                </div>}
            </TrackVisibility>
          </Col>

        </Row>
      </Container>
    </section>
  )
}
